#root {
    margin: auto;
    width: 100%;
    height: 100%;
}

body{
    background-color: white;
}

.App {
    margin: auto;
    width: 850px;
    height: 100%;
    border-radius: 30px;
    color: #404041;

    background-color: wheat;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.Feed {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
}

.Post {
    margin: 25px;
    padding: 0em 2em 2em 2em;
    text-align: left;
    width: 600px;
    background-color: #faad3f;
    border: solid 1px #404041;
    border-radius: 30px;
}

.PostWriter {
    background-color: #f48120 !important;
}

.emojiContainer {
    border-radius: 25px;
    background-color: white;
    border: solid #404041 1px;
    position: relative;
    min-height: 71.8px;

    bottom: -55px;
    grid-column: 3 / 4;
    grid-row: 3;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    gap: 0;
}

.postWrapper {
    display: grid;
    grid-template-columns: 200px 200px 200px;
    grid-template-rows: auto;
}

.emojiBtn {
    padding: 0;
}

.reactionCount {
    position: relative;
    bottom: -5px;
    text-align: center;
}

@keyframes wobble {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

.clicked {
    animation-name: wobble;
    animation-duration: 0.2s;
}

.postUsername {
    margin-right: 70px;
    overflow: hidden;
    
    font-size: 30px;
    grid-column: 1 / 3;
    grid-row: 3;
    position: relative;
    bottom: -65px;
    text-align: left;
}

.postUsername>a {
    position: absolute;
    bottom: 50%;
}

.postTitle{
    overflow-wrap: break-word;
    hyphens: manual;
    grid-column: 1 / 4;
    grid-row: 1;
}

.postContent{
    overflow-wrap: break-word;
    hyphens: manual;
    grid-column: 1 / 4;
    grid-row: 2 / 4;
    padding-bottom: 40px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg) scale(1)
    }
    50% {
        transform: rotate(180deg) scale(1.6)
    }
    100% {
        transform: rotate(360deg) scale(1)
    }
}

.rotate {
    animation-name: spin;
    animation-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.btn {
    width: 50px;
    height: 50px;
}

.sendBtn {
    margin: 11px;
}

.loginBtn {
    margin: 11px;
}

.btn:hover {
    transform: scale(1.2);
}

.postWrapperWelcome {
    grid-column: 1 / 4;
    grid-row: 1;
}

.postWrapperTitle {
    grid-column: 1 / 4;
    grid-row: 2;
}

.postWrapperTitle>input {
    border: solid #404041 1px;
    border-radius: 8px;
    width: 100%;
    height: 30px;
}

.postWrapperContent> * {
    margin-top: 0.5em;
    border: solid #404041 1px;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    resize: none;
}

input, textarea{
    font-family: inherit;
    font-size: inherit;
}

.postWrapperPost{
    grid-column: 1 / 4;
    grid-row: 2;
    width: 100%;
}

.logo ~ h1 {
    margin-top: 0;
}
